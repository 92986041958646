export const productService = {
   getAllProductCategories,
   deleteProductCategory,
   createProductCategory,
   updateProductCategory,
   resendDocuments,
   uploadProduct,
   downloadProduct,
   get
};


// get all product categories
function getAllProductCategories() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/products/categories`, requestOptions).then(handleResponse);
}
// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}
// delete product category
function deleteProductCategory(product_category_id) {
   const requestOptions = {
      method: 'delete',
   }

   return fetch(
      `${process.env.REACT_APP_BASE_URL}/products/category/delete/${product_category_id}`,
      requestOptions
   ).then(handleResponse)
}


function get(product_category_id) {
   const requestOptions = {
      method: 'get',
   }

   return fetch(
      `${process.env.REACT_APP_BASE_URL}/products/${product_category_id}`,
      requestOptions
   ).then(handleResponse)
}
// add product category
function createProductCategory(object) {
   const requestOptions = {
      method: 'POST',
      body: object
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/products/category/create`, requestOptions).then(handleResponse);
}

// update product category
function updateProductCategory(id, object) {
   const requestOptions = {
      method: 'PUT',
      body: object
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/products/category/update/${id}`, requestOptions).then(handleResponse);
}
// resend documents
function resendDocuments(order_id) {
   const requestOptions = {
      method: 'GET'
   }
   return fetch(`${process.env.REACT_APP_BASE_URL}/products/resendDocuments/${order_id}`, requestOptions).then(handleResponse);

}

// download product
function downloadProduct(id) {
   const requestOptions = {
      method: 'get'
   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/products/downloadProduct/${id}`, requestOptions).then(handleResponse);
}
// upload product
function uploadProduct(formData, id) {
   const requestOptions = {
      method: 'PUT',
      body: formData
   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/products/uploadProduct/${id}`, requestOptions).then(handleResponse);
}

