

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Box, Typography, Divider } from "@material-ui/core";
import { CustomCard } from 'components/GlobalComponents';

// redux action
import {
	onEmailChanged,
	onPasswordChanged,
	signinUserWithJwt
} from 'actions';

import Auth from '../../Auth/Auth';
const auth = new Auth();



class Signin extends Component {
	//constructor
	constructor(props) {
		super(props);
		this.state = {
			formErrors: {
				blankEmail: false,
				invalidEmail: false,
				blankPassword: false
			},
			value: 0
		}
	}

	componentDidMount() {
		this.user = localStorage.getItem('user_id');
		if (this.user) {
			window.location.replace('/app/dashboard')
		}
	}
	/**
	 * Function to login user using Firebase
	 */
	async onUserLogin() {
		const { email, password } = this.props;
		let fieldValidationErrors = this.state.formErrors;
		if (email === "") { fieldValidationErrors.blankEmail = true; }
		if (password === "") { fieldValidationErrors.blankPassword = true; }
		if (!this.validateEmail(email)) { fieldValidationErrors.invalidEmail = true; }
		await this.setState({
			formErrors: fieldValidationErrors
		})
		if (email !== '' && password !== '') {
			this.props.signinUserWithJwt(this.props, this.props.history);
		}
	}

	/**
	 * Function to detect email changes
	 */
	onEmailChanged(e) {
		let fieldValidationErrors = this.state.formErrors;
		fieldValidationErrors.blankEmail = false;
		this.setState({ formErrors: fieldValidationErrors })
		this.props.onEmailChanged(e.target.value);
	}

	/**
	 * Function to detect login password changes
	 */
	onPasswordChanged(e) {
		let fieldValidationErrors = this.state.formErrors;
		fieldValidationErrors.blankPassword = false;
		this.setState({ formErrors: fieldValidationErrors });
		this.props.onPasswordChanged(e.target.value);
	}

	/**
	* Function is use for check the email validation.
	*/
	validateEmail(email) {
		let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
		return emailValid;
	}

	/**
	 * Function to show error
	 */
	renderError() {
		if (this.props.error) {
			return (
				<div style={{ backgroundColor: 'white' }}>
					<TextField>{this.props.error}</TextField>
				</div>
			);
		}
	}

	/**
	 * On User Sign Up
	 */
	onUserSignUp() {
		this.props.history.push('/signup');
	}

	loginAuth0() {
		auth.login();
	}

	onJwtLogin(e) {
		// e.preventDefault();
		const { email, password } = this.props;
		this.setState({ submitted: true });
		// const { dispatch } = this.props;
		if (email && password) {
			this.props.signinUserWithJwt(this.props, this.props.history)
		}
	}
	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		const { blankEmail, blankPassword, invalidEmail } = this.state.formErrors;
		const { email, password, error } = this.props;
		return (
			<div>
				<div className="session-wrapper session-wrapper-v2">
					<Box className="session-box" mx="auto" display="flex" justifyContent="center" alignItems="center">
						<Box width="100%">
							<Box textAlign="center" className="session-logo" >

							</Box>

							<CustomCard>
								<form className="login-form text-center">
									<Typography variant="subtitle2" >Melden Sie sich An Sofort Handelsregister</Typography>

									<Box my={3}>
										<TextField
											required
											fullWidth
											variant="outlined"
											id="email"
											type="email"
											name="email"
											placeholder="Bitte geben Sie Ihre E-Mail-Adresse ein."
											className="outlined-input"
											value={email}
											onChange={(email) => this.onEmailChanged(email)}
											error={blankEmail || invalidEmail || error ? true : false}
										/>
										{blankEmail &&
											<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>E-Mails dürfen nicht leer sein.</Box>
										}
										{!blankEmail && invalidEmail &&
											<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>The email address is badly formatted.</Box>
										}
									</Box>
									<Box mb={3}>
										<TextField
											required
											fullWidth
											variant="outlined"
											id="login-password"
											placeholder="Bitte geben Sie Ihr Login-Passwort ein."
											className="outlined-input"
											type="password"
											value={password}
											error={blankPassword || error ? true : false}
											onChange={this.onPasswordChanged.bind(this)}
										/>
										{blankPassword &&
											<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>Kennwort darf nicht leer sein</Box>
										}
									</Box>
									<Box mb="20px">
										<Button
											color="primary"
											className="btn-block blockBtn w-100"
											variant="contained"
											size="large"
											onClick={() => this.onUserLogin()}
										>
											Anmelden
										</Button>
									</Box>
									<Divider></Divider>
									<Box display="flex" justifyContent="center" alignItems="center" pt={2}>

									</Box>
								</form>
							</CustomCard>

						</Box>
					</Box>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ authUser, settings }) => {
	const { loading, email, password, error } = authUser;
	const { isDarkModeActive } = settings;
	return { loading, email, password, error, isDarkModeActive };
};

export default connect(mapStateToProps, {
	onEmailChanged,
	onPasswordChanged,
	signinUserWithJwt
})(Signin);
