export const statisticService = {
   getDailyStatistic,
   getMonthlyStatistic,
   getCustomerMonthlyStatistic,
   getMonthlyAmountStatistic,
   number_of_accounts,
   documents_monthly_statistic,
   getExtraExpenses,
   updateExtraExpenses,
   addExtraExpenses,
   getExtraExpensesByDate
};

// /number of accounts
function number_of_accounts() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/number_of_accounts`, requestOptions).then(handleResponse);

}
// get daily statistic
function getDailyStatistic() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/dailyStatistic`, requestOptions).then(handleResponse);
}
// get order monthly statistic
function getMonthlyStatistic(dateObject) {
   const requestOptions = {
      method: 'POST',
      body: dateObject,
      headers: {
         'Content-type': 'application/json',
      },
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/orderMonthlyStatistic`, requestOptions).then(handleResponse);
}


// get documents monthly statistic
function documents_monthly_statistic(dateObject) {
   const requestOptions = {
      method: 'POST',
      body: dateObject,
      headers: {
         'Content-type': 'application/json',
      },
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/documents_monthly_statistic`, requestOptions).then(handleResponse);
}
// get customer monthly statistic
function getCustomerMonthlyStatistic(dateObject) {
   const requestOptions = {
      method: 'POST',
      body: dateObject,
      headers: {
         'Content-type': 'application/json',
      },
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/customerMonthlyStatistic`, requestOptions).then(handleResponse);
}

// get  monthly amount statistic
function getMonthlyAmountStatistic(dateObject) {
   const requestOptions = {
      method: 'POST',
      body: dateObject,
      headers: {
         'Content-type': 'application/json',
      },
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/amountMonthlyStatistic`, requestOptions).then(handleResponse);
}
// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}

// /get Extra Expenses 
function getExtraExpenses() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/getExtraExpenses`, requestOptions).then(handleResponse);

}

function getExtraExpensesByDate(object) {
   const requestOptions = {
      method: 'POST',
      body: object,
      headers: {
         'Content-type': 'application/json',
      },
   }
   return fetch(`${process.env.REACT_APP_BASE_URL}/getExtraExpensesByDate`, requestOptions).then(handleResponse);

}

function addExtraExpenses(object) {
   const requestOptions = {
      method: 'POST',
      body: object,
      headers: {
         'Content-type': 'application/json',
      },
   }
   return fetch(`${process.env.REACT_APP_BASE_URL}/createExtraExpenses`, requestOptions).then(handleResponse);
}
// update Extra Expenses 
function updateExtraExpenses(object, id) {
   const requestOptions = {
      method: 'put',
      body: object,
      headers: {
         'Content-type': 'application/json',
      },
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/updateExtraExpenses/${id}`, requestOptions).then(handleResponse);

}