

export const TurbaDatacustomerService = {
   getAll,
   getById,
   getProtocols,
   update,
   add
};

function update(id, object) {
   const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(object)
   };

   return fetch(`${process.env.REACT_APP_TURBA_URL}/customer/update/${id}`, requestOptions)
      .then(handleResponse)

}

function add(object) {
   const requestOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(object)
   };

   return fetch(`${process.env.REACT_APP_TURBA_URL}/customer/register`, requestOptions)
      .then(handleResponse)

}

function getProtocols(customer_id) {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_TURBA_URL}/customer/protocol/${customer_id}`, requestOptions).then(handleResponse);
}
// get all customers
function getAll() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_TURBA_URL}/customer/getcustomers`, requestOptions).then(handleResponse);
}
function getById(customer_id) {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_TURBA_URL}/customer/getCustomerById/${customer_id}`, requestOptions).then(handleResponse);
}

// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}