

export const crmService = {
   get,
   update,
   get_website_settings,
   update_website_status
};


// get crm settings
function get() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/crm/get`, requestOptions).then(handleResponse);
}

// edit crm
function update(crm_general_id, object) {
   const requestOptions = {
      method: 'PUT',
      body: object
   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/crm/update/${crm_general_id}`, requestOptions).then(handleResponse);
}
// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}

// get website settings
function get_website_settings() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/crm/check_website_status`, requestOptions).then(handleResponse);

}
// update website status
function update_website_status(object) {
   const requestOptions = {
      method: 'Put',
      body: object,
      headers: {
         'Content-type': 'application/json',
      },
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/crm/update_website_status`, requestOptions).then(handleResponse);

}