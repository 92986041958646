export const vipAccountService = {
    getVipAccountProducts,
    createVipAccountProduct,
    deleteVipAccountProduct,
    updateVipAccountProduct,
    customerSubscriptions,
    customerSubscriptionsByCustomer,
    getProductById,
    getActiveSubscription,
    updateStatus
};

function updateStatus(id, object) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/premuimPackage/updateStatus/${id}`, requestOptions).then(handleResponse);
}// get all customers subscriptions
function customerSubscriptions() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/premuimPackage/customerSubscriptions`, requestOptions).then(handleResponse);
}

// get product by id
function getProductById(id) {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/premuimPackage/products/${id}`, requestOptions).then(handleResponse);
}

// get all product 
function getVipAccountProducts() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/premuimPackage/products`, requestOptions).then(handleResponse);
}

// get customer subscriptions by customer id 
function customerSubscriptionsByCustomer(customer_id) {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/premuimPackage/customerSubscriptions/${customer_id}`, requestOptions).then(handleResponse);
}
// create product
function createVipAccountProduct(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/premuimPackage/products/create`, requestOptions).then(handleResponse);
}

// update product 
function updateVipAccountProduct(id, object) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/premuimPackage/products/update/${id}`, requestOptions).then(handleResponse);
}

// delete product
function deleteVipAccountProduct(product_id) {
    const requestOptions = {
        method: 'delete',
    }

    return fetch(
        `${process.env.REACT_APP_BASE_URL}/premuimPackage/products/delete/${product_id}`,
        requestOptions
    ).then(handleResponse)
}

//get active subscription 
function getActiveSubscription(customer_id) {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/premuimPackage/customerActiveSubscriptions/${customer_id}`, requestOptions).then(handleResponse);
}
// handle response
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}