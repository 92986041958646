export const apiKeysService = {
    getAll,
    updatekey,
    generatekey,
    addkey,
    getKeysByCustomerId,
    get_key_by_id,
    key_statistique,
    generate_invoice,
    getInvoice,
    apiProtocols
    
};

function apiProtocols(user_id) {
    const requestOptions = {
       method: 'get',
       headers: { 'Content-Type': 'application/json' },
    };
 
    return fetch(`${process.env.REACT_APP_API_URL}/customer/protocol/api/${user_id}`, requestOptions)
       .then(handleResponse)
 
 
 }
// get keys by customer id 
function getKeysByCustomerId(customer_id) {

    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/getKeysByCustomer/${customer_id}`, requestOptions).then(handleResponse);
}
// get all keys
function getAll() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/getAll`, requestOptions).then(handleResponse);
}

//generate key
function generatekey(object) {

    const requestOptions = {
        method: 'Post',
        body: object,
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/generatekey`, requestOptions).then(handleResponse);
}


// update key
function updatekey(id, object) {
    const requestOptions = {
        method: 'PUT',
        body: object,
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/updatekey/${id}`, requestOptions).then(handleResponse);
}

// add api key
function addkey(object) {
    const requestOptions = {
        method: 'Post',
        body: object,
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/addkey`, requestOptions).then(handleResponse);

}
// generate invoice
function generate_invoice(key_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/invoice/create/${key_id}`, requestOptions).then(handleResponse);

}


//get invoice 
function getInvoice(key_id) {
    const requestOptions = {
        method: 'GET',

    };
    return fetch(`${process.env.REACT_APP_API_URL}/invoice/getInvoice/${key_id}`, requestOptions).then(handleResponse);
}
// get api key
function get_key_by_id(key_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/get_key_by_id/${key_id}`, requestOptions).then(handleResponse);
}

// get key statistiques
function key_statistique(key_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/statistique/key_statistique/${key_id}`, requestOptions).then(handleResponse);
}

// handle response
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


