import { combineReducers } from 'redux'
import AuthUserReducer from './AuthUserReducer'


import AppSettingsReducer from './AppSettingsReducer'
import UserSettingsReducer from './UserSettingsReducer'
import MenuListReducer from './MenuListReducer'
import users from './users'
import auth from './auth'
import customers from './customers'
import orders from './orders'
import order_details from './order_details'

const reducers = combineReducers({
  authUser: AuthUserReducer,
  settings: AppSettingsReducer,
  UserSettingsReducer: UserSettingsReducer,
  menuListReducer: MenuListReducer,
  users,
  customers,
  auth,
  orders,
  order_details,
})

export default reducers
