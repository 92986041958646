

export const apiCustomersService = {
   getAll,
   addCustomer,
   editCustomer,
   deleteCustomer,
   getById,
   customerStatistique,
   getDailyStatistic,
   getCustomerMonthlyStatistic,
   getKeyMonthlyStatistic,
   getCustomerActiveKey,
   getKeyDynamicStats
};
// get Key Dynamic Stats
function getKeyDynamicStats(key_id, object) {
   const requestOptions = {
       method: 'post',
       headers: { 'Content-Type': 'application/json' },
       body: object
   };
   return fetch(`${process.env.REACT_APP_API_URL}/statistique/getKeyDynamicStats/${key_id}`, requestOptions).then(handleResponse);

}

// get active key
function getCustomerActiveKey(customer_id) {

   const requestOptions = {
       method: 'get',
       headers: { 'Content-Type': 'application/json' },
   };
   return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/getCustomerActiveKey/${customer_id}`, requestOptions).then(handleResponse);

}

// get all customers
function getAll() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_API_URL}/customer/getcustomers`, requestOptions).then(handleResponse);
}

// get customer by id
function getById(cutomer_id) {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_API_URL}/customer/getCustomerById/${cutomer_id}`, requestOptions).then(handleResponse);
}
// add customer
function addCustomer(object) {
   const requestOptions = {
      method: 'POST',
      body: object,
      headers: { 'Content-Type': 'application/json' },
   };
   return fetch(`${process.env.REACT_APP_API_URL}/customer/register`, requestOptions).then(handleResponse);
}
// edit customer
function editCustomer(id, object) {
   const requestOptions = {
      method: 'PUT',
      body: object,
      headers: { 'Content-Type': 'application/json' },
   };
   return fetch(`${process.env.REACT_APP_API_URL}/customer/update/${id}`, requestOptions).then(handleResponse);
}

// customer Statistique
function customerStatistique(customer_id) {
   const requestOptions = {
      method: 'GET'
   };
   return fetch(`${process.env.REACT_APP_API_URL}/statistique/customerStatistique/${customer_id}`, requestOptions).then(handleResponse);
}

// delete customer
function deleteCustomer(id, object) {
   const requestOptions = {
      method: 'delete'
   };
   return fetch(`${process.env.REACT_APP_API_URL}/customer/delete/${id}`, requestOptions).then(handleResponse);
}
// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}

 // get daily statistic
 function getDailyStatistic() {
   const requestOptions = {
      method: 'GET'
   };
 
   return fetch(`${process.env.REACT_APP_API_URL}/statistique/dailyStatistic`, requestOptions).then(handleResponse);
 }  

  // get customer monthly statistic
function getCustomerMonthlyStatistic(dateObject) {
   const requestOptions = {
      method: 'POST',
      body: dateObject,
      headers: {
         'Content-type': 'application/json',
     },
   };
 
   return fetch(`${process.env.REACT_APP_API_URL}/statistique/customerMonthlyStatistic`, requestOptions).then(handleResponse);
 } 

   // get key monthly statistic
function getKeyMonthlyStatistic(dateObject) {
   const requestOptions = {
      method: 'POST',
      body: dateObject,
      headers: {
         'Content-type': 'application/json',
     },
   };
 
   return fetch(`${process.env.REACT_APP_API_URL}/statistique/keyMonthlyStatistic`, requestOptions).then(handleResponse);
 } 