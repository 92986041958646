/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import {
  OrdersGridComponent,
  ShowOrderComponent,
  AsyncFullPageUrlsComponent,
} from 'routes'

const Orders = ({ match }) => (
  <Switch>
    <Redirect
      exact
      from={`${match.url}/`}
      to={`${match.url}/ordersList`}
    ></Redirect>
    <Route
      path={`${match.url}/ordersList`}
      component={OrdersGridComponent}
    ></Route>
    <Route
      path={`${match.url}/showOrder/:order_id`}
      component={ShowOrderComponent}
    ></Route>
    <Route
      path={`${match.url}/`}
      component={AsyncFullPageUrlsComponent}
    ></Route>
  </Switch>
)
export default Orders
