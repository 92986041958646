/**
 * Header Component
*/
/*eslint-disable*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { AppBar, Toolbar, Tooltip, IconButton, Box, Hidden, TextField, Icon } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HorizontalMenu from 'components/HorizontalMenu/HorizontalMenu.js'

//component
import FullScreen from './Components/FullScreen';

import HeaderUserBlock from './Components/HeaderUserBlock';

const drawerWidth = 260;

const styles = theme => ({
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth + 'px',
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	contentJustify: {
		justifyContent: 'space-between'
	},
	menuButton: {
		marginLeft: '-80px',
		color: theme.palette.common.white,
		marginRight: theme.spacing(2),
		'& .MuiSvgIcon-root': {
			fontSize: '1.8125rem'
		},
		[theme.breakpoints.down('md')]: {
			marginLeft: '-6px',
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: '-7px',
		},
	},
	textLight: {
		color: theme.palette.text.primary,
	},
	ToggleBtn: {
		marginLeft: '-12px',
		color:theme.palette.text.primary,
		transition: theme.transitions.create(['margin'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	
	activeBar: {
		opacity: 1,
		visibility: 'visible',
		transform: 'translateX(0) scale(1)',
	},
	inputBar: {
		width: 'calc(100% - 40px)',
		'& .MuiInputBase-root': {
			'&:before, &:after': {
				display: 'none',
			}
		}
	},
	closeIcon: {
		width: 40
	},
	horizontalHead: {
		'& .MuiToolbar-root': {
			paddingLeft: 0,
		}
	}
});

class Header extends Component {
	constructor() {
		super()
		
	}


	UNSAFE_componentWillMount() {
		this.updateDimensions();
	}

	componentDidMount() {
		const { windowWidth } = this.state;
		window.addEventListener("resize", this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
	}

	render() {
		const { toggleSidebar, open, toggleResponsiveSidebar, openHorizontal } = this.props;
		const { classes } = this.props;
		const { windowWidth } = this.state;
		return (
			<div className="hk-header">
				<AppBar
					position="fixed"
					color="default"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: windowWidth < 1280 ? false : open,
						[classes.horizontalHead]: openHorizontal,
						[`rtl-header`]:!open,
					})}
				>
					
					<Toolbar className={classes.contentJustify}>
						<Box display="flex" alignItems="center">
							{!openHorizontal ?
								<Hidden mdDown implementation="css">
									<IconButton
										color="inherit"
										aria-label="open drawer"
										onClick={toggleSidebar}
										edge="start"
										className={clsx(classes.menuButton, {
											[classes.ToggleBtn]: open === false,
										},'hamburger-icon')}
									>
										<MenuIcon />
									</IconButton>
								</Hidden>
								:
								null
							}
							<Hidden lgUp implementation="css">
								<IconButton
									color="inherit"
									aria-label="open drawer"
									edge="start"
									onClick={toggleResponsiveSidebar}
									className={`${classes.menuButton} ham-menu ${classes.textLight}`}
								>
									<MenuIcon />
								</IconButton>
							</Hidden>
							{openHorizontal ?
								<div>
									<Box className="logo-wrap" bgcolor="primary.main" mr={2} py="19px" px={4} lineHeight={0.8}>
										<Box component={Link} to="/dashboard" display="inline-block" lineHeight={0.8}>
										</Box>
									</Box>
								</div>
								:
								null
							}
							
							
						</Box>
						<Box className="horizontal-icon" display="flex" alignItems="center">
							
							<Hidden xsDown implementation="css" className="h-btn-full-scr res-hide">
								<Box>
									<FullScreen iconColor={classes.textLight} />
								</Box>
							</Hidden>
							<Box className="h-btn-user">
								<HeaderUserBlock />
							</Box>
						</Box>

					</Toolbar>
				</AppBar>
				{openHorizontal ?
					<HorizontalMenu />
					:
					null
				}
			</div>
		);
	}
}

// map state to props
const mapStateToProps = ({ settings }) => {
	return { settings }
}

export default withRouter(connect(mapStateToProps, {})(withStyles(styles)(Header)));