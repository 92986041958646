import axios from 'axios'
import { USER_LOADED, AUTH_ERROR } from './Types'
import setAuthToken from '../utils/setAuthToken'

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.user_id) {
    setAuthToken(JSON.parse(localStorage.user_id))
  }

  try {
    const res = await axios.get('http://localhost:5000/api/auth')

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    })
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    })
  }
}
