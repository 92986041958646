/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  ApiCustomersComponent,
  ApiShowCustomersComponent
} from 'routes'

const ApiCustomers = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/customersList`}
      component={ApiCustomersComponent}
    ></Route>
    <Route
      path={`${match.url}/show/:customer_id`}
      component={ApiShowCustomersComponent}
    ></Route>
  </Switch>
)
export default ApiCustomers
