/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import {
  AddCustomerComponent,
  EditCustomerComponent,
  ShowCustomerComponent,
  CustomersGridComponent,
  AsyncFullPageUrlsComponent,
} from 'routes'

const Customers = ({ match }) => (
  <Switch>
    <Redirect
      exact
      from={`${match.url}/`}
      to={`${match.url}/`}
    ></Redirect>
    <Route
      path={`${match.url}/list`}
      component={CustomersGridComponent}
    ></Route>
    <Route
      path={`${match.url}/add`}
      component={AddCustomerComponent}
    ></Route>
    <Route
      path={`${match.url}/edit/:customer_id`}
      component={EditCustomerComponent}
    ></Route>
    <Route
      path={`${match.url}/show/:customer_id`}
      component={ShowCustomerComponent}
    ></Route>
    <Route
      path={`${match.url}/`}
      component={AsyncFullPageUrlsComponent}
    ></Route>
  </Switch>
)
export default Customers
