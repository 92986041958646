/**
 * App Redux Action Types
 */

// CRM
// CRM user
export const ADD_USER = 'ADD_USER'
export const USER_ERROR = 'USER_ERROR'
export const GET_SINGLE_USER = 'GET_SINGLE_USER'
export const EDIT_USER = 'EDIT_USER'
export const GET_ALL_ROLES = 'GET_ALL_ROLES'
export const ROLE_ERROR = 'ROLE_ERROR'
// CRM customer
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR'
export const GET_SINGLE_CUSTOMER = 'GET_SINGLE_CUSTOMER'
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER'
// CRM auth
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
// CRM Order
export const GET_SINGLE_ORDER = 'GET_SINGLE_ORDER'
export const ORDER_ERROR = 'ORDER_ERROR'
// CRM Order_Details
export const GET_SINGLE_ORDER_DETAILS = 'GET_SINGLE_ORDER_DETAILS'
export const ORDER_DETAILS_ERROR = 'ORDER_DETAILS_ERROR'
export const GET_ALL_ORDERS_DETAILS_BY_ORDER_ID =
  'GET_ALL_ORDERS_DETAILS_BY_ORDER_ID'

// Auth Actions
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGING_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const SIGNUP_USER = 'SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE'
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGIN_EMAIL_CHANGED = 'SIGNIN_EMAIL_CHANGED'
export const LOGIN_PASSWORD_CHANGED = 'SIGNIN_PASSWORD_CHANGED'



// App Settings
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR'
export const MINI_SIDEBAR = 'MINI_SIDEBAR'
export const DARK_MODE = 'DARK_MODE'
export const RTL = 'RTL'
export const HORIZONTAL_MENU = 'HORIZONTAL_MENU'
export const CHOOSE_THEME = 'CHOOSE_THEME'
export const NOTIFICATION_SIDEBAR = 'NOTIFICATION_SIDEBAR'


// Contact Grid
export const ADD_NEW_CONTACT = 'ADD_NEW_CONTACT'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'


// User Settings
export const EMAIL_ON_SWITCH_CHANGE = 'EMAIL_ON_SWITCH_CHANGE'
export const SET_LANGUAGE = 'SET_LANGUAGE'

// Menu List
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const TOGGLE_THIRD_MENU = 'TOGGLE_THIRD_MENU'
export const TOGGLE_FOURTH_MENU = 'TOGGLE_FOURTH_MENU'
export const ONLOAD_TOGGLE_MENU = 'ONLOAD_TOGGLE_MENU'

//JWT
export const JWT_LOGIN_REQUEST = 'JWT_USERS_LOGIN_REQUEST'
export const JWT_LOGIN_SUCCESS = 'JWT_USERS_LOGIN_SUCCESS'
export const JWT_LOGIN_FAILURE = 'JWT_USERS_LOGIN_FAILURE'
