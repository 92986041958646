/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import {
  AddUserComponent,
  EditUserComponent,
  UsersGridComponent,
  AsyncFullPageUrlsComponent,
} from 'routes'

const Users = ({ match }) => (
  <Switch>
    <Redirect
      exact
      from={`${match.url}/`}
      to={`${match.url}/`}
    ></Redirect>
    <Route
      path={`${match.url}/list`}
      component={UsersGridComponent}
    ></Route>
    <Route path={`${match.url}/add`} component={AddUserComponent}></Route>
    <Route
      path={`${match.url}/edit/:user_id`}
      component={EditUserComponent}
    ></Route>
    <Route
      path={`${match.url}/`}
      component={AsyncFullPageUrlsComponent}
    ></Route>
  </Switch>
)
export default Users
