/**
 * admin header component
 */
/* eslint-disable */
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import NavListItem from './NavListItem';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleThirdMenu, toggleMenu, toggleFourthMenu, onLoadToggleMenu } from 'actions';
import { ListItem, Box, ListItemText, Switch } from '@material-ui/core';
import menuItems from 'assets/Data/MenuItems';
import apiMenuItems from 'assets/Data/ApiMenuItems';
class SidebarContent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			apiSideBarStatus: this.props.history.location.pathname.indexOf('api') != -1 ? true : false,
			navLinks: this.props.history.location.pathname.indexOf('api') != -1 ? apiMenuItems.data : menuItems.data,
		}
	}

	toggleApiSideBarStatus(e) {
		this.setState({
			apiSideBarStatus: e
		})
		if (e === true) {
			this.setState({
				navLinks: apiMenuItems.data
			})
			this.props.history.push("/app/api_dashboard")
		} else if (e === false) {
			this.setState({
				navLinks: menuItems.data
			})
			this.props.history.push("/app/dashboard")
		}
	}

	getPlanName(name) {
		let newName = name.replace("-", " ");
		return newName
	}

	componentDidMount() {
		let currentURL = window.location.href
		let currentIndex
		for (let i = 0; i < this.props.navLinks.length; i++) {
			if (this.props.navLinks[i].menu == currentURL.split('/')[4]) {
				currentIndex = i;
			}
		}
		this.onLoadToggleMenu(currentIndex);
	}

	// on load toggle menu api or web
	onLoadToggleMenu(index) {
		this.props.onLoadToggleMenu(index)
		if (this.state.apiSideBarStatus === false) {
			this.setState({
				navLinks: this.props.navLinks
			})
		} else if (this.state.apiSideBarStatus === true) {
			this.setState({
				navLinks: this.state.navLinks
			})
		}
	}

	toggleMenu(index) {
		this.props.toggleMenu(index)
		if (this.state.apiSideBarStatus === false) {
			this.setState({
				navLinks: this.props.navLinks
			})
		} else if (this.state.apiSideBarStatus === true) {
			this.setState({
				navLinks: this.state.navLinks
			})
		}

	}
	toggleThirdMenuAndCloseSidebar(index) {
		this.props.toggleThirdMenu(index)
		if (this.state.apiSideBarStatus === false) {
			this.setState({
				navLinks: this.props.navLinks
			})
		} else if (this.state.apiSideBarStatus === true) {
			this.setState({
				navLinks: this.state.navLinks
			})
		}
		if (this.props.closeSidebar) {
			this.props.closeSidebar()
		}
	}
	toggleThirdMenu(index) {
		this.props.toggleThirdMenu(index)
		if (this.state.apiSideBarStatus === false) {
			this.setState({
				navLinks: this.props.navLinks
			})
		} else if (this.state.apiSideBarStatus === true) {
			this.setState({
				navLinks: this.state.navLinks
			})
		}
	}

	toggleFourthMenu(fourthindex) {
		this.props.toggleFourthMenu(fourthindex)
		if (this.state.apiSideBarStatus === false) {
			this.setState({
				navLinks: this.props.navLinks
			})
		} else if (this.state.apiSideBarStatus === true) {
			this.setState({
				navLinks: this.state.navLinks
			})
		}
		if (this.props.closeSidebar) {
			this.props.closeSidebar()
		}
	}


	render() {
		const { closeSidebar } = this.props;
		return (
			<div>
				<List className="menu-wrap" style={{ padding: 0, }}>
					{this.state.navLinks && this.state.navLinks.map((Navlink, index) => {

						return (

							<NavListItem
								menu={Navlink} key={index}
								toggleMenu={() => this.toggleMenu(index)}
								toggleThirdMenuAndCloseSidebar={(e) => this.toggleThirdMenuAndCloseSidebar(e)}
								closeSidebar={closeSidebar}
							/>
						)
					})}
					<ListItem
						disableRipple
						button
						component="li"
						className="list-item"
						style={{ color: "white" }}>
						<div style={{ "width": "100%" }}>

							<Box style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'start',
								width: '100%',
							}}>





								<ListItemText primary="Api" style={{ paddingLeft: 12 }} />
								<Switch
									style={{ fontSize: 20, width: 25 }}
									size="small"
									edge="end"
									color="primary"
									onChange={(e) => this.toggleApiSideBarStatus(e.target.checked)}
									checked={this.state.apiSideBarStatus}
									value= {this.state.toggleApiSideBarStatus}
								/>

							</Box>

						</div>

					</ListItem>
				</List>



			</div>
		);
	}
}

const mapStateToProps = ({ menuListReducer }) => {
	return menuListReducer;
};

export default withRouter(connect(mapStateToProps, {
	toggleThirdMenu,
	toggleMenu,
	toggleFourthMenu,
	onLoadToggleMenu
})(SidebarContent));