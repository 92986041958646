//import { authHeader } from '../_helpers';

export const userService = {
  signup,
  login,
  getAll,
  refreshToken,
  deleteUser,
  getSingleUserById,
  getAllRoles,
  editUser,
  getUserById,
  addUser
}

function signup(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  }
  return fetch(`http://localhost:4000/users/signup`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user_id', JSON.stringify(user))

      return user
    })
}

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  }

  return fetch(`${process.env.REACT_APP_BASE_URL}/auth`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user_id', JSON.stringify(user))
      return user
    })
}

function refreshToken() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ username, password })
  }
  return fetch(`http://localhost:4000/users/refreshToken`, requestOptions).then(
    handleResponse
  )
}

// get user by id
function getSingleUserById(user_id) {
  const requestOptions = {
    method: 'GET',
  }

  return fetch(
    `${process.env.REACT_APP_BASE_URL}/users/user/${user_id}`,
    requestOptions
  ).then(handleResponse)
}

//get all users
function getAll() {
  const requestOptions = {
    method: 'GET',
  }

  return fetch(`${process.env.REACT_APP_BASE_URL}/users/all`, requestOptions).then(
    handleResponse
  )
}

// delete user
function deleteUser(user_id) {
  const requestOptions = {
    method: 'delete',
  }

  return fetch(
    `${process.env.REACT_APP_BASE_URL}/users/delete/${user_id}`,
    requestOptions
  ).then(handleResponse)
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        localStorage.removeItem('user_id')
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}

// get all roles
function getAllRoles () {
      const requestOptions = {
        method: 'get',
      }
      return fetch(
        `${process.env.REACT_APP_BASE_URL}/roles/all`,
        requestOptions
      ).then(handleResponse)
}


// edit user
function editUser(user_id,object) {
  const requestOptions = {
     method: 'PUT',
     body:object
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/users/edit_user/${user_id}`, requestOptions).then(handleResponse);
} 
// add user
function addUser(object) {
  const requestOptions = {
     method: 'POST',
     body:object
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/users/add_user`, requestOptions).then(handleResponse);
} 

// get user by id
function getUserById(user_id) {
  const requestOptions = {
     method: 'GET'
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/users/user/${user_id}`, requestOptions).then(handleResponse);
} 

