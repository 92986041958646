/**
 * Code splitting Components
 * AsyncComponents
 */
import React from 'react'
import Loadable from 'react-loadable'
import { HulkPageLoader } from 'components/GlobalComponents'

// Dasboard Urls
const AsyncFullPageUrlsComponent = Loadable({
  loader: () => import('routes/FullPageUrls'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
//  Dashboard 
const AsyncDashboardComponent = Loadable({
  loader: () => import('routes/Dashboard/Dashboard'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
// Dashboard 1
const AsyncApiDashboardComponent = Loadable({
  loader: () => import('routes/Api/ApiDashboard/ApiDashboard'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

// Error Page 404
const AsyncErrorPage404Component = Loadable({
  loader: () => import('routes/Error/404'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
// Error Page 500
const AsyncErrorPage500Component = Loadable({
  loader: () => import('routes/Error/500'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
// users
const UsersGridComponent = Loadable({
  loader: () => import('routes/_users/Users'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
//add user
const AddUserComponent = Loadable({
  loader: () => import('routes/_users/AddUser'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
//edit user
const EditUserComponent = Loadable({
  loader: () => import('routes/_users/EditUser'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

// users
const CustomersGridComponent = Loadable({
  loader: () => import('routes/_customers/Customers'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
//add customer
const AddCustomerComponent = Loadable({
  loader: () => import('routes/_customers/AddCustomer'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
//edit customer
const EditCustomerComponent = Loadable({
  loader: () => import('routes/_customers/EditCustomer'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
//show customer
const ShowCustomerComponent = Loadable({
  loader: () => import('routes/_customers/ShowCustomer'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
// orders
const OrdersGridComponent = Loadable({
  loader: () => import('routes/_orders/Orders'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

//show order
const ShowOrderComponent = Loadable({
  loader: () => import('routes/_orders/ShowOrder'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
// User Settings
const AsyncUserSettingsComponent = Loadable({
  loader: () => import("routes/UserSettings"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
// crm Settings
const AsyncCrmSettingsComponent = Loadable({
  loader: () => import('routes/CrmSettings'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

// producst
const ProductsComponent = Loadable({
  loader: () => import('routes/_products/Products'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
// producst
const UpdateProductsComponent = Loadable({
  loader: () => import('routes/_products/Products/Components/update'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

// companies
const CompaniesComponent = Loadable({
  loader: () => import('routes/_companies/Companies'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

// show company
const ShowCompanyComponent = Loadable({
  loader: () => import('routes/_companies/ShowCompany'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})



// Vip Account customers
const VipAccountCustomersComponent = Loadable({
  loader: () => import('routes/_vipAccount/Customers'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

// Api documentation
const DocumentationComponent = Loadable({
  loader: () => import('routes/_documentation/Documentation'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
// api cutomers list
const ApiCustomersComponent = Loadable({
  loader: () => import('routes/Api/_apiCustomers/Customers'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
// show api custommers
const ApiShowCustomersComponent = Loadable({
  loader: () => import('routes/Api/_apiCustomers/showCustomer'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

const ApikeysComponent = Loadable({
  loader: () => import('routes/Api/_apikey/keys'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
// show api key
const ApiShowKeyComponent = Loadable({
  loader: () => import('routes/Api/_apikey/showKey'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
const ApiUpdatePackageComponent = Loadable({
  loader: () => import('routes/Api/_apiPackages/updatePackage'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})



const ApiPackagesComponent = Loadable({
  loader: () => import('routes/Api/_apiPackages/packages'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

const AsyncTurbaDataCustomersComponent = Loadable({
  loader: () => import('routes/TurbaData/Customers/List'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
const   AsyncTurbaDataCustomerDetailsComponent= Loadable({
  loader: () => import('routes/TurbaData/Customers/showCustomer'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
const AsyncTurbaDataCustomerUpdateComponent = Loadable({
  loader: () => import('routes/TurbaData/Customers/Update'),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
export {
  AsyncFullPageUrlsComponent,
  AsyncDashboardComponent,
  AsyncErrorPage404Component,
  AsyncErrorPage500Component,
  UsersGridComponent,
  AddUserComponent,
  EditUserComponent,
  AddCustomerComponent,
  EditCustomerComponent,
  ShowCustomerComponent,
  CustomersGridComponent,
  OrdersGridComponent,
  ShowOrderComponent,
  AsyncCrmSettingsComponent,
  ProductsComponent,
  AsyncUserSettingsComponent,
  CompaniesComponent,
  ShowCompanyComponent,
  VipAccountCustomersComponent,
  DocumentationComponent,
  ApiCustomersComponent,
  ApiShowCustomersComponent,
  ApikeysComponent,
  AsyncApiDashboardComponent,
  ApiPackagesComponent,
  ApiShowKeyComponent,
  ApiUpdatePackageComponent,
  AsyncTurbaDataCustomersComponent,
  AsyncTurbaDataCustomerDetailsComponent,
  UpdateProductsComponent,
  AsyncTurbaDataCustomerUpdateComponent,
}
