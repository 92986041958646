/**
 * Blog Routing File
 */
 import React from 'react'
 import { Route, Switch } from 'react-router-dom'
 import {
    ApiPackagesComponent,
    ApiUpdatePackageComponent
 } from 'routes'
 
 const ApiPackages = ({ match }) => (
   <Switch>
     <Route
       path={`${match.url}/update/:package_id`}
       component={ApiUpdatePackageComponent}
     ></Route>
     <Route
       path={`${match.url}/`}
       component={ApiPackagesComponent}
     ></Route>
      
   </Switch>
   
 )
 export default ApiPackages
 