import {
  ADD_CUSTOMER,
  CUSTOMER_ERROR,
  GET_SINGLE_CUSTOMER,
  EDIT_CUSTOMER,
} from '../actions/Types'

const initialState = {
  customer: {}, // instead of null, error: customer is undefined !!
  customers: [],
  loading: true,
  error: {},
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ADD_CUSTOMER:
    case GET_SINGLE_CUSTOMER:
    case EDIT_CUSTOMER:
      return {
        ...state,
        customer: payload,
        loading: false,
      }

    case CUSTOMER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }

    default:
      return state
  }
}
