/**
 * Main APP
 */
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'


// Hulk CSS
import './lib/hulkCss'
// App Conatiner
import App from './container/App'

// Store
import { store } from './store'
import { loadUser } from './actions/auth'
import setAuthToken from './utils/setAuthToken'



if (localStorage.user_id) {
  setAuthToken(localStorage.user_id)
}

const MainApp = () => {
  useEffect(() => {
    store.dispatch(loadUser)
  }, [])

  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <Switch>
            <Route path='/' component={App} />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  )
}

export default MainApp
