/**
 * Ag Grid Table
 */
import React, { Component } from 'react'
import { RedocStandalone } from 'redoc';
import { Box } from "@material-ui/core";
class ApiSHDocumentation extends Component {
  constructor(props) {
    super(props)
  }



  render() {
    return (
      <div style={{
        "height": "100vh",
        overflowY: "auto",
      }}>
        <Box mx="auto" alignItems="center">

          <RedocStandalone
            specUrl="https://api.sofort-handelsregister.com:3002/uploads/apiSH.json"
            options={{
              nativeScrollbars: true,
              theme: { colors: { primary: { main: '#dd5522' } } },
              "hideDownloadButton": true 
            }}
          />
        </Box></div>
    )
  }
}

export default ApiSHDocumentation
