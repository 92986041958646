

export const orderService = {
   getAll,
   deleteOrder,
   updateOrder,
   getByCustomer,
   getSingleOrderById,
   getAllOrdersDetailsByOrderId,
   orderPendingEmail,
   orderInvoiceEmail,
   getInvoiceByOrder,
   update_order_detail_product
};

// update order detail product
function update_order_detail_product(order_detail_id, object) {
   const requestOptions = {
      method: 'put',
      body: object,
      headers: { 'Content-Type': 'application/json' },
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/orders/update_order_detail_product/${order_detail_id}`, requestOptions).then(handleResponse);
}
// get all orders
function getAll() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/orders/all`, requestOptions).then(handleResponse);
}
// get all orders
function getByCustomer(customer_id) {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/orders/customer/${customer_id}`, requestOptions).then(handleResponse);
}
// delete order
function deleteOrder(order_id) {
   const requestOptions = {
      method: 'delete'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/orders/delete/${order_id}`, requestOptions).then(handleResponse);
}

// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}

// update order
function updateOrder(order_id, status) {
   const requestOptions = {
      method: 'put',
      body: status,
      headers: { 'Content-Type': 'application/json' },
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/orders/updateStatus/${order_id}`, requestOptions).then(handleResponse);
}
function getSingleOrderById(order_id) {
   const requestOptions = {
      method: 'get'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/orders/order/${order_id}`, requestOptions).then(handleResponse);
}

function getAllOrdersDetailsByOrderId(order_id) {
   const requestOptions = {
      method: 'get'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/orders/orderdetails/${order_id}`, requestOptions).then(handleResponse);
}

//mail order pending
function orderPendingEmail(object) {
   const requestOptions = {
      method: 'POST',
      headers: {
         'Content-type': 'application/json',
      },
      body: object
   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/orders/orderdetails/mail`, requestOptions).then(handleResponse);
}

//mail order pending
function orderInvoiceEmail(order_id) {
   const requestOptions = {
      method: 'GET',

   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/invoices/resend/${order_id}`, requestOptions).then(handleResponse);
}


//get invoice 
function getInvoiceByOrder(order_id) {
   const requestOptions = {
      method: 'GET',

   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/invoices/order/${order_id}`, requestOptions).then(handleResponse);
}