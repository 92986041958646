/**
 * Router service file
*/
import Dashboard from 'routes/Dashboard'
import Users from 'routes/_users'
import Orders from 'routes/_orders'
import Customers from 'routes/_customers'
import companies from 'routes/_companies'
import vipAccount from 'routes/_vipAccount'
import ApiCustomers from 'routes/Api/_apiCustomers'
import ApiKeys from 'routes/Api/_apikey'
import ApiPackages from 'routes/Api/_apiPackages'
import ApiDashboard from 'routes/Api/ApiDashboard'
import ExtraExpenses from 'routes/_expences'
import products from 'routes/_products'
// Async component
import {
	AsyncCrmSettingsComponent,
	AsyncUserSettingsComponent,
	DocumentationComponent
} from 'routes';
import TurbaDataCustomers from 'routes/TurbaData/Customers'

export default [

	{
		path: 'dashboard',
		component: Dashboard
	},
	{
		path: 'api_dashboard',
		component: ApiDashboard
	},
	{
		path: 'users',
		component: Users
	},
	{
		path: 'customers',
		component: Customers
	},
	{
		path: 'orders',
		component: Orders
	},
	{
		path: 'crm-settings',
		component: AsyncCrmSettingsComponent
	},
	{
		path: 'extra-expenses',
		component: ExtraExpenses
	},
	{
		path: 'user-settings',
		component: AsyncUserSettingsComponent
	},
	{
		path: 'products',
		component: products
	},
	{
		path: 'companies',
		component: companies
	},
	{
		path: 'Premium-pakete',
		component: vipAccount
	},
	{
		path: 'documentation',
		component: DocumentationComponent
	},

	{
		path: 'api_customer',
		component: ApiCustomers
	},
	{
		path: 'api_key',
		component: ApiKeys
	},
	{
		path: 'api_packages',
		component: ApiPackages
	},
	{
		path:'turbaData/customers',
		component: TurbaDataCustomers
	}
]