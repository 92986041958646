/**
 * Blog Routing File
 */
 import React from 'react'
 import { Route, Switch } from 'react-router-dom'
 import {
    ApikeysComponent,
    ApiShowKeyComponent
 } from 'routes'
 
 const ApiKeys = ({ match }) => (
   <Switch>
     <Route
       path={`${match.url}/keysList`}
       component={ApikeysComponent}
     ></Route>
      <Route
       path={`${match.url}/show/:key_id`}
       component={ApiShowKeyComponent}
     ></Route>
    
   </Switch>
 )
 export default ApiKeys
 