/**
 * Blog Routing File
 */
 import React from 'react'
 import { Route, Redirect, Switch } from 'react-router-dom'
 import {
    
    VipAccountCustomersComponent,
    AsyncFullPageUrlsComponent
 } from 'routes'
 
 const vipAccount = ({ match }) => (
   <Switch>
     <Redirect
       exact
       from={`${match.url}/`}
       to={`${match.url}/vip-account`}
     ></Redirect>
     
     <Route
       path={`${match.url}/customers`}
       component={VipAccountCustomersComponent}
     ></Route>
      <Route
      path={`${match.url}/`}
      component={AsyncFullPageUrlsComponent}
    ></Route>
   </Switch>
 )
 export default vipAccount
 