import { GET_SINGLE_ORDER, ORDER_ERROR } from '../actions/Types'

const initialState = {
  order: {}, // instead of null, error: order is undefined !!
  orders: [],
  loading: true,
  error: {},
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_SINGLE_ORDER:
      return {
        ...state,
        order: payload,
        loading: false,
      }

    case ORDER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }

    default:
      return state
  }
}
