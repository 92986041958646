import {
  ADD_USER,
  USER_ERROR,
  GET_SINGLE_USER,
  EDIT_USER,
  GET_ALL_ROLES
} from '../actions/Types'

const initialState = {
  user: {}, // instead of null, error: user is undefined !!
  loading: true,
  error: {},
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ADD_USER:
    case GET_SINGLE_USER:
    case EDIT_USER:
    case GET_ALL_ROLES:
      return {
        ...state,
        user: payload,
        loading: false,
      }

    case USER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }

    default:
      return state
  }
}
