
export const companyService = {
   getAll,
   getCompanyById,
   getReviewsByCompany,
   deleteReview,
   getNotices,
   getOfficersByCompany,
   getProductsFromRegisterPortel,
   getBilans,
   getCompanyContact,
   companyBilanzAssetsStat,
   companyBilanzStat,
   getInsolvenzcheck
};

//get Company Contact
function getCompanyContact(company_number) {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/company/contact/${company_number}`, requestOptions).then(handleResponse);

}
// get companies
function getAll(object) {
   const requestOptions = {
      method: 'POST',
      body: object,
      headers: {
         'Content-type': 'application/json',
      },

   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/company/pagination`, requestOptions).then(handleResponse);
}
// get company by id
function getCompanyById(company_id) {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/company/${company_id}`, requestOptions).then(handleResponse);
}
// get review by company
function getReviewsByCompany(company_id) {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/review/company/${company_id}`, requestOptions).then(handleResponse);
}

//delete review
function deleteReview(id) {
   const requestOptions = {
      method: 'delete',

   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/review/delete/${id}`, requestOptions).then(handleResponse);
}

// NOTICES
// get review by company
function getNotices(company_number) {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/notice/${company_number}`, requestOptions).then(handleResponse);
}
// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}

function getOfficersByCompany(company_id) {
   const requestOptions = {
      method: 'Get'
   }
   return fetch(`${process.env.REACT_APP_BASE_URL}/officers/${company_id}`, requestOptions).then(handleResponse);
}

// get getBilans
function getBilans(company_id) {
   const requestOptions = {
      method: 'get',
      headers: {
         'Content-type': 'application/json',
      }
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/products/bilans/${company_id}`, requestOptions).then(handleResponse);
}


// get products from register portel
function getProductsFromRegisterPortel(company_id) {
   const requestOptions = {
      method: 'get',
      headers: {
         'Content-type': 'application/json',
      }
     
   };


   return fetch(`${process.env.REACT_APP_BASE_URL}/products/regiterProtal/${company_id}`, requestOptions).then(handleResponse);
}


//get insolvenzcheck 
function getInsolvenzcheck(company_number) {
   const requestOptions = {
      method: 'GET',
   };


   return fetch(`${process.env.REACT_APP_BASE_URL}/company/checkInsolvency/${company_number}`, requestOptions).then(handleResponse);

}


//get company Bilanz Assets Stat
function companyBilanzAssetsStat(company_number) {
   const requestOptions = {
      method: 'post',
   };


   return fetch(`${process.env.REACT_APP_BASE_URL}/company/companyBilanzAssetsStat/${company_number}`, requestOptions).then(handleResponse);

}
//get AKTIVA/PASSIVA stat 
function companyBilanzStat(company_number) {
   const requestOptions = {
      method: 'post',
   };


   return fetch(`${process.env.REACT_APP_BASE_URL}/company/companyBilanzStat/${company_number}`, requestOptions).then(handleResponse);

}