/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  ProductsComponent,
  UpdateProductsComponent
} from 'routes'

const Products = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/update`}
      component={UpdateProductsComponent}
    ></Route>
    <Route
      path={`${match.url}/`}
      component={ProductsComponent}
    ></Route>
     
   
  </Switch>
)
export default Products
