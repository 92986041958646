

export const apiPackagesService = {
    getAll,
    create,
    update,
    delete_package,
    get_package
};


// get all Packages
function getAll() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/package/all`, requestOptions).then(handleResponse);
}
// get package by id
function get_package(package_id) {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/package/get_package/${package_id}`, requestOptions).then(handleResponse);
}

// add Package
function create(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/package/add`, requestOptions).then(handleResponse);
}

// update Package
function update(id, object) {
    const requestOptions = {
        method: 'put',
        headers: {
            'Content-type': 'application/json',
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/package/update/${id}`, requestOptions).then(handleResponse);
}


// handle response
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

// delete Package
function delete_package (id) {
    const requestOptions = {
        method: 'delete',
        headers: {
            'Content-type': 'application/json',
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/package/delete/${id}`, requestOptions).then(handleResponse);
}
