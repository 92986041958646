export const protokolService = {
    getAll,
    add,
    getByCustomer
 };
 
 
 // get all orders
 function getAll() {
   const requestOptions = {
      method: 'GET'
   };
 
   return fetch(`${process.env.REACT_APP_BASE_URL}/protokols/all`, requestOptions).then(handleResponse);
 } 

 // add protokol
function add(object) {
   const requestOptions = {
      method: 'POST',
       headers: {
         'Content-type': 'application/json',
     },
      body:object
   };
 
   return fetch(`${process.env.REACT_APP_BASE_URL}/protokols/create`, requestOptions).then(handleResponse);
 } 
 
// get protocols by customer id
function getByCustomer(customer_id) {
   const requestOptions = {
     method: 'GET',
   }
 
   return fetch(
     `${process.env.REACT_APP_BASE_URL}/protokols/customer/${customer_id}`,
     requestOptions
   ).then(handleResponse)
 }
 // handle response
 function handleResponse(response) {
    return response.text().then(text => {
       const data = text && JSON.parse(text);
       if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
       }
       return data;
    });
 }