/**
 * Blog Routing File
 */
 import React from 'react'
 import { Route, Switch } from 'react-router-dom'
 import {
   AsyncTurbaDataCustomersComponent,
   AsyncTurbaDataCustomerDetailsComponent,
   AsyncTurbaDataCustomerUpdateComponent
 } from 'routes'
 
 const Customers = ({ match }) => (
   <Switch>
        <Route
       path={`${match.url}/details`}
       component={AsyncTurbaDataCustomerDetailsComponent}
     ></Route>
       <Route
       path={`${match.url}/update`}
       component={AsyncTurbaDataCustomerUpdateComponent}
     ></Route>
     <Route
       path={`${match.url}/list`}
       component={AsyncTurbaDataCustomersComponent}
     ></Route>
     
    
   </Switch>
 )
 export default Customers
 