/**
 * User Block Section
 */
import React, { Component, Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import {
  IconButton,
  List,
  ListItem,
  Button,
  ListSubheader,
  Typography,
  Popover,
  Tooltip,
  Avatar,
} from '@material-ui/core'

// redux action
import { logoutUser } from 'actions'
import { userService } from '_services'

const styles = (theme) => ({
  root: {
    width: '100%',
    minWidth: 300,
    padding: 0,
    '& >a': {
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.05)',
      },
    },
    '& .top-dropdown-menu--item': {
      padding: '20px 12px',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  avatar: {
    '@media (max-width:1560px)': {
      width: 35,
      height: 35,
    },
  },
})

class HeaderUserBlock extends Component {
  constructor(props) {
    super(props)
    this.confirmationDialog = React.createRef()
    this.state = {
      anchorEl: null,
      // User details
      avatar: '',
      salutation: '',
      first_name: '',
      last_name: '',
      birthday_date: '',
      street: '',
      house_number: '',
      zip_code: '',
      city: '',
      country: '',
      phone_number: '',
      mobile_number: '',
      email: '',
      password: '',
      role: '',
      registration_time: '',
    }
    this.parseJwt = this.parseJwt.bind(this)
    this.getUser()
  }
  // generateing id from local storage
  user_id_localStorage = JSON.parse(localStorage.getItem('user_id'))

  parseJwt(token) {
    if (!token) {
      return
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  
  
  parseJwt_id = this.parseJwt(this.user_id_localStorage.token).user.id
  // get user
  getUser() {
    userService
      .getSingleUserById(this.parseJwt_id)
      .then((results) =>
        this.setState({
          first_name: results.first_name,
          last_name: results.last_name,
         avatar: results.avatar,
          registration_time: results.registration_time,
        })
      )
      .catch((err) => {
        console.error('error getData', err)
      })
  }

  //Define function for open dropdown
  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  //Define function for close dropdown
  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }
  /*
   * Logout User
   */
  logoutUser = () => {
    this.setState({
      anchorEl: null,
    })
    this.props.logoutUser()
  }

  render() {
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    const { classes } = this.props
    return (
      <div>
        <Tooltip title='User Profileee' placement='bottom'>
          <IconButton
            aria-describedby={open ? 'simple-popper' : null}
            variant='contained'
            color='primary'
            style={{ padding: '6px' }}
            onClick={this.handleClick}
          >
            <Avatar
              alt='user-thumb'
              className={classes.avatar}
              src={`${process.env.REACT_APP_UPLOADS}/${this.state.avatar}`}
            />
          </IconButton>
        </Tooltip>
        <Popover
          id='simple-popper'
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Fragment>
            <List
              className={`${classes.root} top-dropdown-menu`}
              component='nav'
              aria-labelledby='nested-list-subheader'
              subheader={
                <ListSubheader component='div' id='nested-list-subheader'>
                  <div className='dropdown-header user-info  text-center'>
                    <Avatar
                      alt='user-thumb'
                      className={classes.large}
                      src={`${process.env.REACT_APP_UPLOADS}/${this.state.avatar}`}
                    />
                    <Typography variant='body2'>
                      {this.state.first_name} {this.state.last_name}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {this.state.birthday_date}
                    </Typography>
                    <Button
                      className='btn primary-bg-btn'
                      component={Link}
                      to='/app/user-settings'
                      variant='outlined'
                      color='primary'
                    >
                      {/* Manage your account */}
                      Verwalten Sie Ihr Konto
                    </Button>
                  </div>
                </ListSubheader>
              }
            >
              <ListItem
                component='div'
                className='top-dropdown-menu--item d-block text-center'
              >
                <Button
                  variant='contained'
                  color='primary'
                  onClick={this.logoutUser}
                >
                  {/* Sign out */}
                  Ausloggen
                </Button>
              </ListItem>
            </List>
          </Fragment>
        </Popover>
      </div>
    )
  }
}

const mapStateToProps = ({ settings }) => {
  return settings
}

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
  })(withStyles(styles)(HeaderUserBlock))
)
