import { USER_LOADED, AUTH_ERROR } from '../actions/Types'

const initialState = {
  user_id: JSON.parse(localStorage.getItem('user_id')),
  isAuthenticated: null,
  loading: true,
  user: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      }
    case AUTH_ERROR:
      localStorage.removeItem('user_id')
      return {
        ...state,
        user_id: null,
        isAuthenticated: false,
        loading: false,
      }
    default:
      return state
  }
}
