

export const customerService = {
   all,
   getCustomers,
   getguestCustomers,
   deleteCustomer,
   customersByOrderTotal,
   customersByNumberOfDocuments,
   update_radar,
   getById,
   get_radars_by_customer,
   update_payment_with_invoice,
   edit_customer,
   paymentWithInvoice
};

function all() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/customers/all`, requestOptions).then(handleResponse);

}
function getguestCustomers() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/customers/guest`, requestOptions).then(handleResponse);
}
// get all customers
function getCustomers() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/customers`, requestOptions).then(handleResponse);
}
// get customers By Number Of Documents
function customersByNumberOfDocuments(object) {
   const requestOptions = {
      method: 'POST',
      body: object,
      headers: {
         'Content-type': 'application/json',
      },
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/customersByNumberOfDocuments`, requestOptions).then(handleResponse);
}
// get customers By Order Total
function customersByOrderTotal(object) {
   const requestOptions = {
      method: 'POST',
      body: object,
      headers: {
         'Content-type': 'application/json',
      },
   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/customersByOrderTotal`, requestOptions).then(handleResponse);
}

// delete customer
function deleteCustomer(customer_id) {
   const requestOptions = {
      method: 'put'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/Customers/delete/${customer_id}`, requestOptions).then(handleResponse);
}

// update raddar
function update_radar(customer_id, object) {
   const requestOptions = {
      method: 'put',
      headers: {
         'Content-type': 'application/json',
      },
      body: object
   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/companyRadar/update_raddar/${customer_id}`, requestOptions).then(handleResponse);
}
// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}

// Get single customer by ID
function getById(customer_id) {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/customers/${customer_id}`, requestOptions).then(handleResponse);

}

function edit_customer(customer_id, object) {
   const requestOptions = {
      method: 'put',
      headers: {
         'Content-type': 'application/json',
      },
      body: JSON.stringify(object)
   };

   return fetch(`${process.env.REACT_APP_BASE_URL}/customers/edit_customer/${customer_id}`, requestOptions).then(handleResponse);
}
// update payment with invoice
function update_payment_with_invoice(customer_id, object) {
   const requestOptions = {
      method: 'put',
      headers: {
         'Content-type': 'application/json',
      },
      body: object
   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/customers/update_payment_with_invoice/${customer_id}`, requestOptions).then(handleResponse);
}
function paymentWithInvoice() {
   const requestOptions = {
      method: 'get'
   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/customers/paymentWithInvoice`, requestOptions).then(handleResponse);
}
// get radars by customer
function get_radars_by_customer(customer_id) {
   const requestOptions = {
      method: 'Get',
   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/companyRadar/getRadarsByCustomer/${customer_id}`, requestOptions).then(handleResponse);

}