import {
  GET_SINGLE_ORDER_DETAILS,
  ORDER_DETAILS_ERROR,
  GET_ALL_ORDERS_DETAILS_BY_ORDER_ID,
} from '../actions/Types'

const initialState = {
  order_details: {}, // instead of null, error: order_details is undefined !!
  orders_details: [],
  loading: true,
  error: {},
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_SINGLE_ORDER_DETAILS:
      return {
        ...state,
        order_details: payload,
        loading: false,
      }

    case GET_ALL_ORDERS_DETAILS_BY_ORDER_ID:
      return {
        ...state,
        orders_details: payload,
        loading: false,
      }

    case ORDER_DETAILS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }

    default:
      return state
  }
}
