
import { withStyles } from '@material-ui/styles';
import { Container, Grid, Paper } from '@material-ui/core';

import React from 'react';
import { Box } from '@material-ui/core';
import { forwardRef } from 'react';
import MaterialTable from 'material-table';
import { CustomCard } from 'components/GlobalComponents';
import { DeleteOutline, Edit, Check, Clear, Add } from '@material-ui/icons';
import "react-datepicker/dist/react-datepicker.css";
import { statisticService } from '_services';
//tab content components
import { PageTitleBarWithImage } from 'components/GlobalComponents';



const styles = theme => ({
	Paper: {
		padding: '0.75rem',
		backgroundColor: 'transparent',
		boxShadow: 'none',
		'&:last-child': {
			paddingBottom: '30px',
		}
	},
	root: {
		marginLeft: -9,
		marginRight: -9,
		'& div:nth-child(3)': {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: -10,
			marginRight: -10,
		},
	},
	titleBar: {
		'& .MuiContainer-root': {
			'& >div': {
				'& >div:last-child': {
					'& .title-image': {
						minHeight: 320,
						paddingTop: 20
					}
				}
			}
		}
	}
});

class ExtraExpenses extends React.Component {
	// constructeur
	constructor(props) {
		super(props);
		this.state = {
			tableIcons: {
				Add: forwardRef((props, ref) => <Box color="primary.main">< Add {...props} ref={ref} /></Box>),
				Check: forwardRef((props, ref) => <Box color="primary.main"><Check {...props} ref={ref} /></Box>),
				Clear: forwardRef((props, ref) => <Box color="secondary.main"><Clear {...props} ref={ref} /></Box>),
				Delete: forwardRef((props, ref) => <Box color="secondary.main"><DeleteOutline {...props} ref={ref} /></Box>),
				Edit: forwardRef((props, ref) => <Box color="primary.main"><Edit {...props} ref={ref} /></Box>),
			},
			columns: [],
			date: new Date(),
			extraExpenses: []
		}
		this.getExtraExpenses()
	}
	handleChange = event => {
		console.log(event)
		this.setState({ date: event })
	}

	// get selected Row
	getSelectedRow(rowData) {
		this.setState({ selectedRow: rowData })

	}
	updateAmount(data) {
		statisticService.updateExtraExpenses(JSON.stringify(data), data._id).then(result => {
			this.getExtraExpenses()
		})

	}
	addAmount(data) {
		statisticService.addExtraExpenses(JSON.stringify(data), data._id).then(result => {
			this.getExtraExpenses()
		})
	}
	// get extra expenses
	getExtraExpenses() {
		statisticService.getExtraExpenses().then(result => {
			this.setState({
				extraExpenses: result.extra_expenses,
				columns: [
					{ title: 'Monat', field: 'month', lookup: { 1: 'Januar', 2: 'Februar', 3: 'März', 4: 'April', 5: 'Mai', 6: 'Juni', 7: 'Juli', 8: 'August', 9: 'September', 10: 'Oktober', 11: 'November', 12: 'Dezember' }, },
					{ title: 'Jahr', field: 'year', lookup: { 2021: '2021', 2022: '2022', 2023: '2023', 2024: '2024', 2025: '2025', 2026: '2026', 2027: '2027', 2028: '2028', 2029: '2029', 2030: '2030', 2031: '2031', 2032: '2032' } },
					{ title: 'Summe', field: 'amount'}]
			})
		})
	}
	render() {
		const { classes } = this.props;
		return (
			<div className="hk-user-settings">
				<Box className={`${classes.titleBar} white-btn-color`}>
					<PageTitleBarWithImage
						title="Extraausgaben"
						image="settings.png"
					/>
				</Box>
				<Container maxWidth="lg">
					<Box className="page-space-top" px={{ xs: '12px', lg: 0 }} mb={4}>

						<Paper className={classes.Paper} square>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12} md={12}>
									<CustomCard >
										<Box pt={3} >
											<Grid item xs={12} sm={12} md={12} lg={12}>
												<Box className="geo-table">
													<MaterialTable
														icons={this.state.tableIcons}
														style={{ boxShadow: 'none' }}
														columns={this.state.columns}
														data={this.state.extraExpenses}
														title="Extraausgaben"
														options={{

															actionsColumnIndex: -1,
															sorting: true,
															search: true,
															paging: true,
															toolbar: true
														}}
														editable={{
															onRowAdd: newData =>
																new Promise(resolve => {
																	setTimeout(() => {
																		resolve();
																		this.addAmount(newData)

																	}, 600);
																}),
															onRowUpdate: (newData) =>
																new Promise(resolve => {
																	setTimeout(() => {
																		resolve();
																		this.updateAmount(newData)
																	}, 600);
																}),

														}}

													/>
												</Box>
											</Grid>

										</Box>
									</CustomCard>
								</Grid>
							</Grid>
						</Paper>

					</Box>
				</Container>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(ExtraExpenses);